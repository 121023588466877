import { useEffect, useRef } from 'react';
import './App.scss';

function App() {
  const iframe = useRef(null);
  const imageContainer = useRef(null);
  const clickVideo = () => {
    imageContainer.current.style = "display: none;";
    iframe.current.classList.add("show");
    let video = iframe.current.src + "&autoplay=1";
    iframe.current.src = video;
  }

  const handleResize = () => {
    const width = window.innerWidth;

    if(!(iframe.current)) return;
    iframe.current.style = `width: ${width*.8}px; height: ${width*.45}px;`;
    if(width > 767) {
      iframe.current.style = "width: 660px; height: 400px;";
    }
    if(width > 1023) {
      iframe.current.style = "width: 720px; height: 465px;";
    }
  }
  const handleAutoplay = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const autoPlay = urlParams.get('autoplay');
    if(autoPlay !== null) {
      document.getElementById("video").scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
      clickVideo();
    }
  }
  useEffect(() => {
    handleResize();
    window.addEventListener('load', handleAutoplay);
  })
  window.addEventListener('resize', handleResize);

  return (
    <div className="App">
      <div className="header">
        <h1 className="celebrating">Celebrating the Life & Legacy of</h1>
        <h1 className="butch">Gordon ‘Butch’ Stewart</h1>
        <p className="date">July 6, 1941 – January 4, 2021</p>
      </div>
      <div className="content">
        <div id="video" className="video">
          <div ref={imageContainer} className="image-container" onClick={clickVideo}>
            <img className="thumbnail" src="https://cdn.sandals.com/sandals/v12/images/general/butch-live-memorial/video-placeholder-new.jpg" alt="one man's vision" />
            <img className="play-btn" src="https://cdn.sandals.com/sandals/v12/images/general/butch-live-memorial/play-button.png" alt="play button" />
          </div>
          <iframe ref={iframe} className="video-player" width="320" height="210" src="https://www.youtube.com/embed/iPbXivlJAKI?rel=0" frameBorder="0" allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="one mans vision"></iframe>
        </div>
        <div className="text-container">
          <p className="outpouring-love">The outpouring of love received from all over the world is an extraordinary testament to the impact Gordon “Butch” Stewart had on so many.</p>
          <p className="share-memories">For those wishing to share memories, condolences and personal stories, we invite you to do so at <a href="mailto:allthatsgood@sandals.com">allthatsgood@sandals.com</a>.</p>
          <p className="lieu-flowers">We have introduced the Gordon “Butch” Stewart Tourism & Hospitality Scholarship Program through his esteemed Sandals Corporate University. His commitment to mentoring in the Caribbean will carry on and serve as a beacon of hope for generations to come. We will also celebrate his love of being near the Sea with dedicated Marine Conservation efforts in his name. For more information, visit the <a href="https://sandalsfoundation.org/">Sandals Foundation.</a></p>
          <picture>
            <source srcSet="https://cdn.sandals.com/sandals/v12/images/general/butch-live-memorial/logos-mobile.png"
              media="(max-width: 767px)"/>
            <img className="logos" src="https://cdn.sandals.com/sandals/v12/images/general/butch-live-memorial/logos.png" alt="brand logos" />
          </picture>
        </div>
      </div>
    </div>
  );
}

export default App;
